import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Notes.css";
import { s3Upload } from "../libs/awsLibs";

export default class Notes extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            isDeleting: null,
            note: null,
            remark: "",
            attachmentURL: null,
            licenseURL: null
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL, licenseURL;
            const note = await this.getNote();
            const { remark, reqAttachment, respAttachment } = note;

            if (reqAttachment) {
                attachmentURL = await Storage.vault.get(reqAttachment);
            }

            if (respAttachment) {
                licenseURL = await Storage.get(respAttachment);
            }

            this.setState({
                note,
                remark,
                attachmentURL,
                licenseURL
            });
        } catch (e) {
            alert(e);
        }
    }

    getNote() {
        return API.get("portunes", `/req/${this.props.match.params.id}`);
    }

    validateForm() {
        return this.state.remark.length > 0;
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleFileChange = event => {
        this.file = event.target.files[0];
    }

    saveNote(note) {
        return API.put("portunes", `/req/${this.props.match.params.id}`, {
            body: note
        });
    }

    handleSubmit = async event => {
        let reqAttachment;

        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
            return;
        }

        this.setState({ isLoading: true });

        try {
            if (this.file) {
                reqAttachment = await s3Upload(this.file);
            }

            await this.saveNote({
                remark: this.state.remark,
                reqAttachment: reqAttachment || this.state.note.reqAttachment,
                respAttachment: this.state.note.respAttachment
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className="Notes">
                {this.state.note &&
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="remark">
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.remark}
                            componentClass="textarea"
                        />
                    </FormGroup>
                    {this.state.note.reqAttachment &&
                    <FormGroup>
                        <ControlLabel>License Request Zip File</ControlLabel>
                        <FormControl.Static>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.state.attachmentURL}
                            >
                                {this.formatFilename(this.state.note.reqAttachment)}
                            </a>
                        </FormControl.Static>
                    </FormGroup>}
                    {!this.state.note.reqAttachment &&
                    <FormGroup controlId="file">
                        <ControlLabel>License Request Zip File</ControlLabel>
                        <FormControl onChange={this.handleFileChange} type="file" />
                    </FormGroup>}
                    {this.state.note.respAttachment &&
                    <FormGroup>
                        <ControlLabel>License Zip File</ControlLabel>
                        <FormControl.Static>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={this.state.licenseURL}
                            >
                                {this.formatFilename(this.state.note.respAttachment)}
                            </a>
                        </FormControl.Static>
                    </FormGroup>}
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                </form>}
            </div>
        );
    }
}