const dev = {
    s3: {
        REGION: "ap-northeast-1",
        BUCKET: "portunes-app-api-dev-attachmentsbucket-1dedi1jpevjy4"
    },
    apiGateway: {
        REGION: "ap-northeast-1",
        URL: "https://ub417punl1.execute-api.ap-northeast-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_MN46P53G3",
        APP_CLIENT_ID: "4l9g19nid1f727bgu9u6beirbt",
        IDENTITY_POOL_ID: "ap-northeast-1:1049c96c-4a0e-4cec-9200-2b03f2ccd110"
    }
};

const prod = {
    s3: {
        REGION: "ap-northeast-1",
        BUCKET: "portunes-app-api-prod-attachmentsbucket-1nnyv900b6jr7"
    },
    apiGateway: {
        REGION: "ap-northeast-1",
        URL: "https://wss6fb1ox4.execute-api.ap-northeast-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_m3lNlNEnu",
        APP_CLIENT_ID: "3i4c48nu5dqarrnd73uvnqdabs",
        IDENTITY_POOL_ID: "ap-northeast-1:e129da16-b6a4-4ec2-b586-5bce1663091e"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};