import React, { Component } from "react";
import {FormGroup, FormControl, ControlLabel, HelpBlock} from "react-bootstrap";
import { Auth } from "aws-amplify";
import "./Login.css";
import LoaderButton from "../components/LoaderButton";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            authenticationCode: "",
            loginUser: null
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    validateMfaForm() {
        return this.state.authenticationCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const loginUser = await Auth.signIn(this.state.email, this.state.password);
            this.setState({
                loginUser
            });
        } catch (e) {
            alert(e.message);
        }

        this.setState({isLoading: false});
    }

    handleMfaSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.confirmSignIn(
                this.state.loginUser,
                this.state.authenticationCode,
                "SMS_MFA"
            );

            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
        } catch (e) {
            alert(e.message);
        }
        this.setState({isLoading: false});
    }

    renderMfaForm() {
        return (
            <form onSubmit={this.handleMfaSubmit}>
                <FormGroup controlId="authenticationCode" bsSize="large">
                    <ControlLabel>Authentication Code</ControlLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        value={this.state.authenticationCode}
                        onChange={this.handleChange}
                    />
                    <HelpBlock>Please check your phone for the code.</HelpBlock>
                </FormGroup>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateMfaForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Verify"
                    loadingText="Verifying…"
                />
            </form>
        );
    }

    renderForm() {
        return (
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Login"
                        loadingText="Logging in…"
                    />
                </form>
        );
    }

    render() {
        return (
            <div className="Login">
                {this.state.loginUser === null
                    ? this.renderForm()
                    : this.renderMfaForm()}
            </div>
        );
    }
}